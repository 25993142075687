import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import App from "../../App";
import config from "../../config/api.config.js";
import "../../assets/css/pages/departments.scss";
import SnackbarError from "../../components/alert/SnackbarError";
import SnackbarSuccess from "../../components/alert/SnackbarSuccess";
import Search from "../../components/search/search";
import SortInput from "../../components/sortInput/sortInput";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import Pagination from "../../components/pagination/pagination";
import NothingFound from "../../components/searchNothingFound/searchNothingFound";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import Modal from "../../components/modal/modal";
import OutlinedButton from "../../components/buttons/outlinedButton";
import AddButton from "../../components/auth/button";
import {
  searchProcesses,
  deleteProcess,
  getProcessTasks,
} from "../../redux/process/action";
import { searchUsers } from "../../redux/users/action";
import FileCopy from "@material-ui/icons/FileCopy";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import moment from "moment";
import {
  scheduleProcessTask,
  scheduleProcessTaskEndDate,
} from "../../redux/process/action";
import { handleValidateEmail } from "../../helpers/helpers";
import Autocomplete from "@mui/material/Autocomplete";

const MasterProcesses = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [selectedSort, setSelectedSort] = useState(2);
  const [defaultPage, setDefaultPage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [processDeleteId, setProcessDeleteId] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [masterProcessName, setMasterProcessName] = useState(false);
  const [masterProcessId, setMasterProcessId] = useState(false);
  const [email, setEmail] = useState();
  const [isEmailValid, setIsEmailValid] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [step, setStep] = useState(1);
  const [taskDates, setTaskDates] = useState([]);
  const [option, setOption] = useState("1");
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [emailValue, setEmailValue] = useState();
  const [endDate, setEndDate] = useState();
  const [individualTaskEndDate, setIndividualTaskEndDate] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const abortController = new AbortController();
      if (!props.currentUser) return;

      handleSort({ value: 2 });
      return () => {
        abortController.abort();
      };
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  const getSearchValue = (search) => {
    setSearch(search.trim());
    if (props.currentUser) {
      dispatch(
        searchProcesses({
          isTemplate: true,
          PageNumber: 1,
          Search: search.replace(/\s+/g, " ").trim(),
          Sort: selectedSort,
        })
      );
    }
    setDefaultPage(!defaultPage);
  };

  const handleSort = (e) => {
    setSelectedSort(e.value);
    dispatch(
      searchProcesses({
        isTemplate: true,
        PageNumber: props.pageParams.currentPage,
        Sort: e.value,
        Search: search.replace(/\s+/g, " ").trim(),
      })
    );
  };

  const getPageNumber = (newPage) => {
    dispatch(
      searchProcesses({
        isTemplate: true,
        PageNumber: newPage,
        Sort: selectedSort,
        Search: search.replace(/\s+/g, " ").trim(),
      })
    );
  };

  const deleteMasterProcess = async () => {
    await setButtonClicked(true);
    await dispatch(
      deleteProcess({
        processId: processDeleteId,
      })
    )
      .then(() => {
        setButtonClicked(false);
        setOpenDeleteModal(false);
        setProcessDeleteId("");
        dispatch(
          searchProcesses({
            isTemplate: true,
            Sort: selectedSort,
            PageNumber: 1,
            Search: search.replace(/\s+/g, " ").trim(),
          })
        );
        setDefaultPage(!defaultPage);
        setSuccessMessage("Prozess wurde erfolgreich gelöscht.");
        setOpenAlert(true);
      })
      .catch((err) => {
        setButtonClicked(false);
        setOpenDeleteModal(false);
        setProcessDeleteId(null);
        let errMsg = "Fehler bei Löschen des Prozesses.";
        if (err.response?.data?.errors) {
          errMsg =
            err.response.data.errors[Object.keys(err.response.data.errors)[0]];
        }
        setErrorMessage(errMsg);
        setOpenAlert(true);
      });
  };

  const duplicateTemplateHandler = (processId) => {
    history.push("/add-process", {
      type: "useTemplate",
      step: "createProcess",
      templateData: props.processes[processId],
    });
  };

  const StartOnboardingForProcess = async (id, name) => {
    setOpenModal(true);
    setMasterProcessId(id);
    setMasterProcessName(name);
    getTasks(id);
  };

  const getTasks = async (processId) => {
    let _tasks = [];
    await dispatch(getProcessTasks({ processId: processId })).then((res) => {
      if (res.value?.data) {
        _tasks = res.value.data.tasks;
      }
    });
    setTasks(_tasks);
    setTaskDates(_tasks.map((t) => ({ taskId: t.id, date: "" })));
    return _tasks;
  };

  const handleOptionChange = (e) => {
    setOption(e.target.value);
  };
  const getEndDate = async () => {
    await dispatch(
      scheduleProcessTaskEndDate({
        processId: masterProcessId,
        endDate: endDate,
      })
    ).then((result) => {
      setIndividualTaskEndDate(result);
      setStep(3);
    });
  };
  const runProcess = async () => {
    //TODO: Send option, tasks and dates to the backend. Close the modal. Show success message.
    setOpenModal(false);
    await dispatch(
      scheduleProcessTask({
        processId: masterProcessId,
        option: parseInt(option),
        emailRecipient: email,
        ...(option === "1" && {
          taskDatePairs: tasks.map((task) => {
            return {
              taskId: task.id,
              scheduledDate: taskDates.find((date) => date.taskId === task.id)
                ?.date,
            };
          }),
        }),
        ...(option === "4" && { endDate: endDate }),
      })
    ).then((result) => {
      setOpenModal(false);
      setMasterProcessName("");
      setMasterProcessId(null);
      setStep(1);
      setEmail("");
      setTaskDates([]);
      setIndividualTaskEndDate([]);
      setEndDate();
      setOption("1");
      setSuccessMessage("Prozess wurde erolgreich gestartet");
      setOpenAlert(true);
    });
  };

  const closeStartOnboardingForProcessModal = () => {
    setOpenModal(false);
    setMasterProcessName("");
    setMasterProcessId(null);
    setStep(1);
    setEmail("");
    setTaskDates([]);
    setIndividualTaskEndDate([]);
    setEndDate("");
    setOption("1");
  };
  const goToNextStep = () => {
    if (option === "1") {
      setStep(3);
    } else if (option === "4") {
      getEndDate();
    }
  };

  const validateStepTwo = (option) => {
    switch (option) {
      case "1":
        if (email && option && taskDates.every((td) => td && td.date))
          return true;
        else return false;
      case "4":
        if (email && option && endDate) return true;
        else return false;
    }
  };

  return (
    <App>
      {errorMessage && (
        <SnackbarError
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setErrorMessage("");
          }}
        >
          {errorMessage}
        </SnackbarError>
      )}
      {successMessage && (
        <SnackbarSuccess
          open={openAlert}
          onClose={() => {
            setOpenAlert(false);
            setSuccessMessage("");
          }}
        >
          {successMessage}
        </SnackbarSuccess>
      )}
      <Breadcrumbs navigations={["Masterprozesse"]} />
      <div className="departments__top">
        <div className="departments__top--serach">
          <Search getSearchValue={getSearchValue} />
          <Modal
            title={
              step === 1
                ? `${t(
                    "masterproccess.start_onboarding"
                  )} "${masterProcessName}"`
                : masterProcessName
            }
            open={openModal}
            closeModal={closeStartOnboardingForProcessModal}
          >
            {step === 1 && (
              <React.Fragment>
                <label>{t("masterproccess.add_email")}:</label>

                <Autocomplete
                  freeSolo
                  id="combo-box-demo"
                  options={userSuggestions}
                  onChange={(_, newValue) => {
                    setEmailValue(newValue);
                    const isValidEmail = handleValidateEmail(
                      newValue ? newValue.email : ""
                    );
                    setIsEmailValid(isValidEmail);
                    setEmail(newValue ? newValue.email : "");
                  }}
                  onInputChange={async (e) => {
                    if (!e || !e.target.value || e.target.value === "") {
                      setUserSuggestions([]);
                      return;
                    }
                    setEmail(e.target.value);
                    const isValidEmail = handleValidateEmail(e.target.value);
                    setIsEmailValid(isValidEmail);
                    const request = searchUsers({ Search: e.target.value });
                    if (request.payload) {
                      const response = await request.payload;
                      setUserSuggestions(response.data.users);
                    }
                  }}
                  inputValue={email || ""}
                  value={emailValue || ""}
                  getOptionLabel={(u) =>
                    u ? `${u.firstName} ${u.lastName} (${u.email})` : ""
                  }
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        type="text"
                        {...params.inputProps}
                        placeholder="E-mail"
                      />
                    </div>
                  )}
                />
                <div className="row col">
                  <AddButton
                    className="departments__modal--add ml-0 mr-3 mt-2"
                    valid={email && isEmailValid ? true : false}
                    onClick={() => setStep(2)}
                  >
                    {t("next")}
                  </AddButton>
                  <div className="departments__modal--add ml-0 mr-3 mt-2">
                    <OutlinedButton
                      onClick={closeStartOnboardingForProcessModal}
                    >
                      {t("Abbrechen")}
                    </OutlinedButton>
                  </div>
                </div>
              </React.Fragment>
            )}

            {step === 2 && (
              <React.Fragment>
                <FormControl>
                  <FormLabel> {t("masterproccess.choose_option")}:</FormLabel>
                  <RadioGroup
                    name="radio-buttons-group"
                    onChange={handleOptionChange}
                    value={option}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label={t("masterproccess.for_each_task")}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label={t("masterproccess.previous_one")}
                      disabled
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio />}
                      label={t("masterproccess.all_at_once")}
                      disabled
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio />}
                      label={t("masterproccess.automatic")}
                    />
                  </RadioGroup>
                </FormControl>

                {option === "1" ? (
                  <TableContainer className="departments__table mt-2">
                    <Table aria-label="simple table" size="small">
                      <TableBody>
                        {tasks &&
                          tasks.map((task, i) => (
                            <TableRow
                              key={i}
                              className="departments__table-row"
                            >
                              <TableCell width="70%" component="th" scope="row">
                                {task.name}
                              </TableCell>
                              <DatePicker
                                placeholderText={t("masterproccess.date")}
                                style={{ marginRight: "10px" }}
                                selected={taskDates[i].date}
                                minDate={new Date()}
                                onChange={(date) => {
                                  taskDates[i].date = date;
                                  setTaskDates([...taskDates]);
                                }}
                              />
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : option === "4" ? (
                  <>
                    <div style={{ width: "150px" }}>
                      <DatePicker
                        placeholderText={t("masterproccess.end_date")}
                        selected={endDate}
                        minDate={new Date()}
                        onChange={(date) => setEndDate(date)}
                      />
                    </div>
                    <TableContainer className="departments__table mt-2">
                      <Table aria-label="simple table" size="small">
                        <TableBody>
                          {tasks &&
                            tasks.map((task, i) => (
                              <TableRow
                                key={i}
                                className="departments__table-row"
                              >
                                <TableCell
                                  width="70%"
                                  component="th"
                                  scope="row"
                                  style={{ padding: "15px 20px" }}
                                >
                                  {task.name}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : null}
                <div className="row col">
                  <AddButton
                    className="departments__modal--add ml-0 mr-3 mt-3"
                    valid={validateStepTwo(option)}
                    onClick={() => goToNextStep()}
                  >
                    {t("next")}
                  </AddButton>
                  <div className="departments__modal--add ml-0 mr-3 mt-3">
                    <OutlinedButton
                      onClick={closeStartOnboardingForProcessModal}
                    >
                      {t("Abbrechen")}
                    </OutlinedButton>
                  </div>
                </div>
              </React.Fragment>
            )}

            {step === 3 && (
              <React.Fragment>
                {t("masterproccess.click_run")}
                <TableContainer className="departments__table mt-4">
                  <Table aria-label="simple table" size="small">
                    <TableBody>
                      {tasks &&
                        tasks.map((task, i) => (
                          <TableRow key={i} className="departments__table-row">
                            <TableCell width="70%" component="th" scope="row">
                              {task.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {option === "4" &&
                                individualTaskEndDate.value.data[i].item2 &&
                                moment(
                                  individualTaskEndDate.value.data[i].item2
                                ).format("DD.MM.YYYY")}

                              {option === "1" &&
                                taskDates[i].date &&
                                moment(taskDates[i].date).format("DD.MM.YYYY")}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="row col">
                  <AddButton
                    className="departments__modal--add ml-0 mr-3 mt-3"
                    valid={
                      option === "1"
                        ? email &&
                          option &&
                          taskDates.some((td) => td && td.date)
                        : email && option && endDate
                    }
                    onClick={runProcess}
                  >
                    {t("masterproccess.run")}
                  </AddButton>
                  <div className="departments__modal--add ml-0 mr-3 mt-3">
                    <OutlinedButton
                      onClick={closeStartOnboardingForProcessModal}
                    >
                      {t("Abbrechen")}
                    </OutlinedButton>
                  </div>
                  <div className="departments__modal--add ml-0 mr-3 mt-3">
                    <OutlinedButton onClick={() => setStep(2)}>
                      {t("edit")}
                    </OutlinedButton>
                  </div>
                </div>
              </React.Fragment>
            )}

            {/* <AddButton className="departments__modal--add">Next</AddButton> */}
            {/* <MembersSelect
              className="sort-input positions__select-department"
              placeholder={"Standortverantwortlich"}
              selectedOption={selectedHead}
              onChange={headChangeHandler}
            />
            <div className="departments__modal--buttons">
              <OutlinedButton
                onClick={() => {
                  setOpenModal(false);
                  setLocationName("");
                  setLocationEditId(null);
                  setSelectedHead("");
                }}
              >
                {t("Abbrechen")}
              </OutlinedButton>
              <AddButton
                className="departments__modal--add"
                valid={locationName ? true : false}
                buttonClicked={buttonClicked}
                onClick={locationEditId ? EditLocation : AddLocation}
              >
                {locationEditId ? "Speichern" : "Hinzufügen"}
              </AddButton>
            </div> */}
          </Modal>
        </div>
        <SortInput handleSort={handleSort} selectedSort={selectedSort} />
      </div>
      <TableContainer className="departments__table">
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "white" }}>
              <TableCell>{t("No")}.</TableCell>
              <TableCell align="left">{t("Name")}</TableCell>
              <TableCell align="right">{t("Optionen")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoading ? (
              <TableRow style={{ backgroundColor: "white" }}>
                <TableCell
                  colSpan={7}
                  style={{ textAlign: "center", paddingTop: "5rem" }}
                >
                  <div>
                    <CircularProgress style={{ color: config.colorPrimary }} />
                  </div>
                </TableCell>
              </TableRow>
            ) : !props.isLoading && !props.processIds?.length ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <NothingFound>
                    {t("Aktuell keine Prozesse hinterlegt")}
                  </NothingFound>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {!props.isLoading &&
                  props.processIds.length > 0 &&
                  props.processIds?.map((id) => {
                    return (
                      props.processes[id] && (
                        <TableRow
                          key={id}
                          className="departments__table-row"
                          style={{ backgroundColor: "white" }}
                        >
                          <TableCell component="th" scope="row">
                            {props.processes[id].no + "."}
                          </TableCell>
                          <TableCell
                            align="left"
                            onClick={() => history.push(`/process/${id}`)}
                          >
                            {props.processes[id].name}
                          </TableCell>

                          <TableCell align="right">
                            <div>
                              <AccessTimeIcon
                                onClick={async () =>
                                  await StartOnboardingForProcess(
                                    id,
                                    props.processes[id].name
                                  )
                                }
                              />
                              <FileCopy
                                onClick={() => duplicateTemplateHandler(id)}
                              />
                              <EditIcon
                                onClick={() =>
                                  history.push(`/edit-process/${id}`, {
                                    type: "edit",
                                    step: "editProcess",
                                  })
                                }
                              />
                              <DeleteIcon
                                onClick={() => {
                                  setOpenDeleteModal(true);
                                  setProcessDeleteId(id);
                                }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!props.processIds?.length && search ? null : (
        <>
          {Object.keys(props.pageParams).length > 0 && (
            <Pagination
              params={props.pageParams}
              getPageNumber={getPageNumber}
              defaultPage={defaultPage}
            />
          )}
        </>
      )}
      <Modal
        title={""}
        open={openDeleteModal}
        closeModal={() => {
          setOpenDeleteModal(false);
          setProcessDeleteId("");
        }}
      >
        <p>{`Soll der MasterProzess ${props.processes[processDeleteId]?.name} gelöscht werden?`}</p>
        <div className="departments__modal--buttons">
          <OutlinedButton
            onClick={() => {
              setOpenDeleteModal(false);
              setProcessDeleteId("");
            }}
          >
            {t("Abbrechen")}
          </OutlinedButton>
          <AddButton
            className="departments__modal--add"
            valid={true}
            buttonClicked={buttonClicked}
            onClick={deleteMasterProcess}
          >
            {t("Löschen")}
          </AddButton>
        </div>
      </Modal>
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isLoading: state.process.isLoading,
  allProcessIds: state.process.allIds,
  processes: state.process.byId,
  processIds: state.process.searchIds,
  pageParams: state.process.pageParams,
});

export default connect(mapStateToProps, null)(MasterProcesses);
