import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "./dashboard.scss";
import "../../assets/css/pages/dashboard.scss";
import MediaCard from "../../../src/components/card/MediaCard";
import App from "../../App";
import { searchArticles } from "../../redux/article/action";
import auth from "../../auth";
import { getUserById } from "../../redux/users/action";
import {
  getProcessesByUser,
  getProcessTasks,
  getProcessById,
} from "../../redux/process/action";
import {
  getTasksWaitingForApproval,
  getTasksByBenefactor,
  getAssignedTasks,
} from "../../redux/task/action";
import HomeLogo from "../../assets/images/noContent.png";
import config from "../../config/api.config.js";
import { getFileType, logError } from "../../helpers/helpers";
import Search from "../../components/search/search";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { searchMedias } from "../../redux/media/action";
import MediaDisplayModal from "../Medias/MediaDisplayModal";
import {ReactComponent as IconMore} from "../../assets/images/icon-view-more.svg"

const Dashboard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [member, setMember] = useState([]);
  const [assignedProcess, setAssignedProcess] = useState([]);
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [tasksForApproval, setTasksForApproval] = useState([]);
  const [assignedBenefactorTasks, setAssignedBenefactorTasks] = useState([]);
  const [search, setSearch] = useState("");
  const [medias, setMedias] = useState([]);
  const [displayUrl, setDisplayUrl] = useState("");
  const [displayMedia, setDisplayMedia] = useState(false);
  const [displayMediaName, setDisplayMediaName] = useState("");
  const [displayMediaTitle, setDisplayMediaTitle] = useState("");
  
  useEffect(() => {
    const abortController = new AbortController();
    if (!props.currentUser) return;

    const fetchData = async () => {
      dispatch(searchArticles({ Include_owner: true, Sort: 2, PageSize: 5 }));
      const userId = auth.getId();
      if (props.members[userId]) {
        setMember(props.members[userId]);
      } else {
        await dispatch(getUserById({ id: userId }))
          .then((res) => {
            if (res.value?.data) {
              let user = res.value.data;
              user["id"] = res.value.data.userId;
              setMember(user);
            }
          })
          .catch((err) => {
            logError(err);
          });
      }

      await fetchAssignedTasks({ userId: auth.getId() });
      await fetchUserProcesses({ userId: auth.getId() });
      await fetchTasksWaitingForApproval({ userId: userId });
      await fetchTasksByBenefactor({ userId: userId });
    };

    fetchData();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  const hanldeCardClick = (id) => {
    history.push(`/article/${id}`);
  };

  const hanldeTaskCardClick = (task, userType) => {
    history.push(`/task/${task.id}`, {
      task: task,
      member: member,
      userType: userType,
      isActive: true
    });
  };

  const hanldeProcssCardClick = (id) => {
    history.push(`/process/${id}`);
  };

  const filterAssignedTasks = async (tasks) => {
    let _tasks = [];
    for (const task of tasks) {
      if (task.preTask === null) {
        _tasks.push(task);
      } else {        
        if(!task.toggle){
          await checkIfTaskIsCompleted(task.processId, task.preTask).then(
            (isCompleted) => {
              if (isCompleted) {
                _tasks.push(task);
              }
            }
          );
        }else{
          const processTasks = await getTasksOfProcess(task.processId);
          const canRun = await checkIfTaskCanExecuteConcurrently(processTasks, task.preTask, task.name);
          if(canRun){
            _tasks.push(task);
          }
        }       
      }
    }

    if (_tasks.length > 0) {
      formatTasks(_tasks).then((formattedTask) => {
        let _assignedTasks = [];
        if (formattedTask?.length > 0) {
          _assignedTasks = formattedTask.filter(
            (item) => item.processActivated === true
          );
        }
        setAssignedTasks(_assignedTasks);
      });
    } else {
      setAssignedTasks([]);
    }
  };

  const checkIfTaskIsCompleted = async (processId, taskId) => {
    const processTasks = await getTasksOfProcess(processId);
    const task = processTasks?.filter((item) => item.id === taskId);
    if (task?.length > 0 && task[0].compeleted && task[0].approved) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfTaskCanExecuteConcurrently = (processTasks, taskId, name) => { 
    const preTask = processTasks?.find(
      (item) => item.id === taskId
    );
    let preTaskParent = [];

    if(taskId === null || preTask?.preTask === null){
      return true
    }else{
      preTaskParent = processTasks?.find(
        (item) => item.id === preTask?.preTask
      );
    }

    if(!preTask?.toggle){
      if(preTaskParent?.compeleted && preTaskParent?.approved){
        return true;
      }else{
        return false;
      }
    }else{
      return checkIfTaskCanExecuteConcurrently(processTasks, preTaskParent?.preTask, name);
    }
  };

  const getTasksOfProcess = async (processId) => {
    if (props.processTasks[processId]) {
      return props.processTasks[processId];
    } else {
      return await dispatch(getProcessTasks({ processId: processId })).then(
        (res) => {
          if (res.value?.data) {
            return res.value.data.tasks;
          }
        }
      );
    }
  };

  const getProcess = async (id) => {
    if (props.processes[id]) {
      return props.processes[id];
    } else {
      return await dispatch(getProcessById({ id: id }))
        .then((res) => {
          if (res.value?.data) {
            return res.value.data;
          }
        })
        .catch((err) => logError(err));
    }
  };

  //to set Task's process name and completed fields
  const formatTasks = async (tasks) => {
    let _tasks = [];
    for (const task of tasks) {
      await getProcess(task.processId).then((process) => {
        task.processName = process && process.name ? process.name : "";
        task.processCompleted =
          process && process.compeleted ? process.compeleted : false;
        task.processActivated =
          process && process.isActivated ? process.isActivated : false;
        task.processImage = process?.image
        _tasks.push(task);
      });
    }
    return _tasks;
  };

  const fetchAssignedTasks = async(params) => {
    await dispatch(getAssignedTasks(params)).then((res) => {
      let _tasks = [];
      if (res.value?.data) {
        if (res.value.data?.tasks?.length > 0) {
          _tasks = res.value.data.tasks.filter(
            (item) => item.compeleted === false
          );
        }
      }
      filterAssignedTasks(_tasks);
    });
  };

  const fetchUserProcesses = async(params) => {
    await dispatch(getProcessesByUser(params)).then(
      (res) => {
        let _processes = [];
        if (res.value?.data) {
          if (res.value.data.process.length > 0) {
            _processes = res.value.data.process.filter((item) => {
              return item.compeleted === false && item.isActivated === true;
            });
          }
        }
        setAssignedProcess(_processes);
      }
    );
  };

  const fetchTasksWaitingForApproval = async(params) => {
    await dispatch(getTasksWaitingForApproval(params)).then(
      (res) => {
        if (res.value?.data) {
          if (res.value.data.tasks.length > 0) {
            formatTasks(res.value.data.tasks).then((formattedTask) => {
              let _tasksWaiting = [];
              if (formattedTask?.length > 0) {
                _tasksWaiting = formattedTask.filter(
                  (item) => item.processActivated === true
                );
              }
              setTasksForApproval(_tasksWaiting);
            });
          } else {
            setTasksForApproval([]);
          }
        }
      }
    );
  };

  const fetchTasksByBenefactor = async(params) => {
    await dispatch(getTasksByBenefactor(params))
      .then((res) => {
        if (res.value?.data) {
          if (res.value.data.tasks.length > 0) {
            formatTasks(res.value.data.tasks).then((formattedTask) => {
              let _benefactortasks = [];
              if (formattedTask.length > 0) {
                _benefactortasks = formattedTask.filter((item) => {
                  return (
                    item.processCompleted === false &&
                    item.processActivated === true
                  );
                });
              }
              setAssignedBenefactorTasks(_benefactortasks);
            });
          } else {
            setAssignedBenefactorTasks([]);
          }
        }
      })
      .catch((err) => {
        logError(err);
      });
  };

  const fetchMedias = async (params) => {
    await dispatch(searchMedias(params)).then((res) => {
      if (res.value?.data) {
        setMedias(res.value.data.medias);
      }
    })
    .catch((err) => {
      logError(err);
    });
  }

  const getSearchValue = (search) => {
    setSearch(search);
    if(!search) searchMedias([]);
    if (props.currentUser) {
      dispatch(searchArticles({ Include_owner: true, Sort: 2, PageSize: 5, Search: search.replace(/\s+/g, ' ').trim()}));
      fetchAssignedTasks({ userId: auth.getId(), Search: search.replace(/\s+/g, ' ').trim() });
      fetchUserProcesses({ userId: auth.getId(), Search: search.replace(/\s+/g, ' ').trim() });
      fetchTasksWaitingForApproval({ userId: auth.getId(), Search: search.replace(/\s+/g, ' ').trim() });
      fetchTasksByBenefactor({ userId: auth.getId(), Search: search.replace(/\s+/g, ' ').trim() });
      fetchMedias({Search: search.replace(/\s+/g, ' ').trim(), Sort: 2});
    }
  };

  const handleMediaDisplay = (media) => {
    let type = getFileType(media.name);
    if (type !== "Document") {
      let url = `${config.resourcesUrl+media.name}`;
      setDisplayUrl(url);
      setDisplayMedia(true);
      setDisplayMediaName(media.name);
      setDisplayMediaTitle(media.title);
    }
  };

  return (
    <App style={{ border: "3px solid red" }}>
      <MediaDisplayModal
        open={displayMedia}
        closeModal={() => {
          setDisplayMedia(false);
          setDisplayUrl("");
          setDisplayMediaName("");
          setDisplayMediaTitle("");
        }}
        mediaUrl={displayUrl}
        title={displayMediaTitle}
        name={displayMediaName}
      />
      {(props.articleIds?.length === 0 &&
        assignedTasks?.length === 0 &&
        assignedProcess?.length === 0 &&
        tasksForApproval?.length === 0 &&
        assignedBenefactorTasks?.length === 0 && search === '') ? (
          <div className="dashboard-no-content">
            <img src={HomeLogo} alt="logo" />
            <p className="text-center dashboard-no-content-text">{`Herzlich Willkommen zu ${config.company} Onboarder`}</p>
          </div>
        ) : null}

      {(props.articleIds?.length === 0 &&
        assignedTasks?.length === 0 &&
        assignedProcess?.length === 0 &&
        tasksForApproval?.length === 0 &&
        assignedBenefactorTasks?.length === 0 && medias.length === 0) ? (
          <>
            {search && <div className="dashboard-no-content">
              <p className="text-center dashboard-no-content-text">{t("no_search_results")}</p>
            </div>}
          </>
        ): (
          <>
            {search && <div className="trainin">
              <h2 className="search__title">{t("search_results")}</h2>
            </div>}
          </>
        )
      }

      {props.articleIds?.length > 0 && (
        <>
          <div className="trainin">
            <span className="category__title">Neueste Artikel</span>      
            <div className="dashboard__top--serach">
            <Search getSearchValue={getSearchValue}/>
          </div> 
            {props.articlePageParams.hasNext && <span className="see-more">
                <IconMore onClick={() => history.push("/articles")}/>
            </span>}                   
          </div>
          <div className="cardHolder">
            {props.articleIds?.map((id, index) => (
              <div className="card" key={id}>
                <MediaCard
                  cardTitle={props.articles[id].title}
                  cardText={props.articles[id].content}
                  hasMarkup={true}
                  type="Article"
                  authorId={props.articles[id].authorId}
                  commentable={props.articles[id].commentable}
                  comments={props.articles[id].comments}
                  cardImage={props.articles[id]?.coverImage?.name ?? ""}
                  onClick={() => hanldeCardClick(id)}
                  cardContentClass="dash-news-card-content"
                  role="Article"
                />
              </div>
            ))}
          </div>
        </>
      )}

      {medias?.length > 0 && search && (
        <>
          <div className="trainin">
          <span className="category__title">Neueste Medien</span>
            {medias.length > 5 && <span className="see-more">
              <Link
                to={`/medias`}
                style={{ color: config.colorSecondary }}
              >
                Mehr anzeigen
              </Link>
            </span>}
          </div>
          <div className={"cardHolder"}>
            {medias?.map((media, index) => (
              <div className={"card"} key={media.id}>
                <MediaCard
                  cardTitle={media.title}
                  cardText={media.description}
                  cardText2={
                    "Medientyp: " + getFileType(media.name)
                  }
                  hasMarkup={true}
                  type={"Media"}
                  authorId={media.creator}                 
                  onClick={() => handleMediaDisplay(media)}
                  cardContentClass={"dash-news-card-content"}
                  role={"Media"}
                />
              </div>
            ))}
          </div>
        </>
      )}

      {assignedTasks?.length > 0 && (
        <>
          <div className="trainin">
          <span className="category__title">Meine Aufgaben</span>
            {assignedTasks && assignedTasks.length > 5 && <span className="see-more">
              <Link
                to={`/my-tasks`}
                style={{ color: config.colorSecondary }}
              >
                Mehr anzeigen
              </Link>
            </span>}
          </div>
          <div className={"cardHolder"}>
            {assignedTasks && assignedTasks.length > 0
              ? assignedTasks.map((task, index) => (
                <React.Fragment key={task.id}>
                  {index < 5 && <div className={"card"}>
                    <MediaCard
                      cardTitle={task.name}
                      cardText={task.description}
                      cardText2={
                        "Prozess: " + props.processes[task.processId]?.name
                      }
                      hasMarkup={false}
                      type={"Task"}
                      authorId={task.responsible}
                      cardImage={task.processImage?.name}
                      onClick={() => hanldeTaskCardClick(task, "task_owner")}
                      cardContentClass={"dash-news-card-content"}
                      role={"Assigned Task"}
                    />
                  </div>}
                  </React.Fragment>
                ))
              : "Aktuell leer."}             
          </div>
        </>
      )}

      {assignedProcess.length > 0 && (
        <>
          <div className="trainin">
          <span className="category__title">Meine Prozesse</span>
            {assignedProcess && assignedProcess.length > 5 && <span className="see-more">
              <Link
                to={`/my-processes`}
                style={{ color: config.colorSecondary }}
              >
                Mehr anzeigen
              </Link>
            </span>}
          </div>
          <div className={"cardHolder"}>
            {assignedProcess && assignedProcess.length > 0
              ? assignedProcess.map((process, index) => (
                <React.Fragment key={process.id}>
                  {index < 5 && <div className={"card"}>
                    <MediaCard
                      cardTitle={process.name}
                      cardText={process.description}
                      hasMarkup={false}
                      type={"Process"}
                      authorId={process.responsible}
                      onClick={() => hanldeProcssCardClick(process.id)}
                      cardContentClass={"dash-news-card-content"}
                      cardImage={process.image?.name}
                      role={"Process"}
                    />
                  </div>}
                </React.Fragment>
                ))
              : "Aktuell leer."}             
          </div>
        </>
      )}

      {tasksForApproval?.length > 0 && (
        <>
          <div className="trainin">
          <span className="category__title">Warten auf Genehmigung</span>
            {tasksForApproval && tasksForApproval.length > 5 && <span className="see-more">
              <Link
                to={`/my-tasks`}
                style={{ color: config.colorSecondary }}
              >
                Mehr anzeigen
              </Link>
            </span>}
          </div>
          <div className={"cardHolder"}>
            {tasksForApproval && tasksForApproval.length > 0
              ? tasksForApproval.map((task, index) => (
                <React.Fragment key={task.id}>
                  {index < 5 && <div className={"card"}>
                    <MediaCard
                      cardTitle={task.name}
                      cardText={task.description}
                      cardText2={"Prozess: " + task.processName}
                      hasMarkup={false}
                      type={"Task"}
                      authorId={task.responsible}
                      cardImage={task.processImage?.name}
                      onClick={() => hanldeTaskCardClick(task, "process_owner")}
                      cardContentClass={"dash-news-card-content"}
                      role={"Approval Task"}
                    />
                  </div>}
                </React.Fragment>
                ))
              : "Aktuell leer."}             
          </div>
        </>
      )}

      {assignedBenefactorTasks?.length > 0 && (
        <>
          <div className="trainin">
          <span className="category__title">Aufgaben zu meinen Gunsten</span>
            {assignedBenefactorTasks && assignedBenefactorTasks.length > 5 && <span className="see-more">
              <Link
                to={`/my-tasks`}
                style={{ color: config.colorSecondary }}
              >
                Mehr anzeigen
              </Link>
            </span>}
          </div>
          <div className={"cardHolder"}>
            {assignedBenefactorTasks && assignedBenefactorTasks.length > 0
              ? assignedBenefactorTasks.map((task, index) => (
                <React.Fragment key={task.id}>
                  {index < 5 && <div className={"card"}>
                    <MediaCard
                      cardTitle={task.name}
                      cardText={task.description}
                      cardText2={"Prozess: " + task.processName}
                      hasMarkup={false}
                      type={"Task"}
                      authorId={task.responsible}
                      cardImage={task.processImage?.name}
                      onClick={() =>
                        hanldeTaskCardClick(task, "task_benefactor")
                      }
                      cardContentClass={"dash-news-card-content"}
                      role={"Benefactor Task"}
                    />
                  </div>}
                </React.Fragment>  
                ))               
              : "Aktuell leer."}            
          </div>        
        </>
      )}
    </App>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isArticleLoading: state.article.isLoading,
  articleIds: state.article.searchIds,
  articles: state.article.byId,
  members: state.user.byId,
  processTasks: state.process.tasks,
  processes: state.process.byId,
  articlePageParams: state.article.pageParams
});

export default connect(mapStateToProps, null)(Dashboard);
