import "babel-polyfill";
import React, { Fragment, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import config from "./config/api.config.js";
import { routes } from "./route";
import { DefaultProtection } from "./components/defaultProtection/defaultProtection";
import CircularProgress from "@material-ui/core/CircularProgress";
import Login from "./pages/Login/Login";
import EmailVerification from "./pages/EmailVerfication/EmailVerification";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import "./assets/css/responsive.scss";
import "flag-icon-css/css/flag-icon.min.css";
import VerifyEmail from "./pages/EmailVerfication/VerifyEmail";
import NotFound from "./pages/Numbers/NotFound";
import Register from "./pages/Register/Register";
import auth from "./auth";
import LocalStorageService from "./LocalStorageService";
import NewPopup from "./components/popupMenu/NewPopup";

export const history = require("history").createBrowserHistory();

i18next
  .use(HttpApi)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "de"],
    fallbackLng: "de",
    debug: false,
    // Options for language detector
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

const loadingMarkup = (
  <div style={{ height: "100vh", textAlign: "center", paddingTop: "45vh" }}>
    <CircularProgress style={{ color: config.colorPrimary}} />
  </div>
);

const Root = () => {
  return (
    <Fragment>
      <Switch>
        <Route path={`${config.publicUrl}/login`} component={Login} />
        <Route exact path={`/`} render={() => <Redirect to="/login" />} />
        <Route path={`${config.publicUrl}/register`} component={Register} />

        <Route
          path={`${config.publicUrl}/confirm-your-email`}
          component={VerifyEmail}
        />
        <Route
          path={`${config.publicUrl}/email-confirmed`}
          component={EmailVerification}
        />
        <Route
          path={`${config.publicUrl}/forgot-password`}
          component={ForgotPassword}
        />
        <Route
          path={`${config.publicUrl}/password-reset`}
          component={ResetPassword}
        />

        {/*test*/}
        <Route path={`${config.publicUrl}/NewPopup`} component={NewPopup} />
        {/*test*/}

        <Route path={`${config.publicUrl}/Register`} component={Register} />

        <Route path={`${config.publicUrl}/404`} component={NotFound} />

        {/* <App> Should Not Be Here */}
        {routes.map(({ path, Component }) => (
          <DefaultProtection
            start
            key={path}
            exact
            path={`${config.publicUrl}${path}`}
            component={Component}
          />
        ))}
        {/* </App> */}

        {auth.isAuthenticated() &&
        !auth.isTokenExpired() &&
        LocalStorageService.get("access_token") ? (
          <Redirect to="/404" />
        ) : (
          <Redirect to="/login" />
        )}
      </Switch>
    </Fragment>
  );
};

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <Provider store={store}>
      <BrowserRouter history={history}>
        <Root />
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
