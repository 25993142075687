import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import InputField from '../../components/inputField/inputField';
import { connect } from "react-redux";
import Dropdown from '../../components/dropdown/singleSelectDropdown';
import { ReactComponent as Upload } from '../../assets/images/upload.svg';
import AddButton from "../../components/auth/button";

const CreateProcess = (props) => {
  const { t } = useTranslation();
  const {
    values,
    setValues,
    processImg,
    selectedUser,
    setSelectedUser,
    onClick,
    showErrorMsg,
    members,
    selectedFile,
    setSelectedFile,
    buttonClicked,
    setButtonClicked,
    allowUserToEdit
  } = props;

  const userSelectHandler = (val) => {
    setSelectedUser({ add: val });
    setValues({ ...values, processRes: { add: val } })
  };
//  console.log(process.env.REACT_APP_API_URL_2 + `/resources/${processImg.name}`)
  const onFileChange = (event) => {
    if (
      event.target.files.length <= 0 ||
      selectedFile?.name === event.target.files[0].name
    )
      return;

    if (!event.target.files[0].type.includes('image')) {
      showErrorMsg(`${ t('course_image_error_message') }`);
      return;
    }
    setSelectedFile(event.target.files[0]);
  };

  const onClickHandler = async () => {
    await setButtonClicked(true);
    onClick();
  }
 
  return(
    <>
    <div className="add-course__first-step">
      <div className="first-step--left" >      
        <InputField
          label={t("Prozessnamen angeben")}
          value={values.processName}
          onChange={(e) => setValues({ ...values, processName: e.target.value })}
          buttonClicked={buttonClicked || !allowUserToEdit}
        />
        <InputField
          label={t('Kurzbeschreibung angeben')}
          value={values.processDes}
          type="multiline"
          rows={5}
          onChange={(e) =>
            setValues({ ...values, processDes: e.target.value })
          }
          buttonClicked={buttonClicked || !allowUserToEdit}
        />
        <Dropdown
          labelWidth={115}
          options={members}
          selected={selectedUser.add}
          setSelected={userSelectHandler}
          label="Verantwortlichen"
          type={"simple"}
          disabled={buttonClicked || !allowUserToEdit}
        />
      </div>
      <div className="first-step--right">
          <div>
            {!selectedFile && !processImg ? (
              <label
                htmlFor="file-upload"
                className="custom-file-upload course-add__image--upload"
              >
                <Upload />
              </label>
            ): (
              <>
                {selectedFile? (
                  <img
                    style={{ width: '16rem', height: '14rem', objectFit: 'cover' }}
                    src={selectedFile.url ?? URL.createObjectURL(selectedFile)}
                    alt='photos'
                  />
                ) : (
                  
                  <img
                      style={{ width: '16rem', height: '14rem', objectFit: 'cover' }}
                      src={processImg ? process.env.REACT_APP_API_URL_2+`/resources/${processImg.name}` : ''}
                      alt='photos'
                    />
                )}
              </>
            )}
            <label className="upload-file__label-text" htmlFor="file-upload">
              {t('Upload Image')}
            </label>
            <input
              id="file-upload"
              className="article-modal__file-upload"
              type="file"
              accept="image/*"
              onChange={onFileChange}
            />
          </div>
      </div>      
    </div>
    <div>
      <AddButton
          className="departments__modal--add process-continue"
          valid={true}
          onClick={onClickHandler}
          valid={
            values.processName &&
            values.processDes &&
            values.processRes 
              ? true
              : false
          }
          buttonClicked={buttonClicked}
        >
          Weiter
        </AddButton>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, null)(CreateProcess);
