import { 
  SEARCH_TASKS,
  ADD_TASK,
  UPDATE_TASK,
  DELETE_TASK,
  TASK_INSERT_FILE,
  TASK_DELETE_FILE,
  TASK_BY_ID,
  TASK_DONE,
  INSERT_TASK_ATTACHMENT,
  TASKS_WAITING_FOR_APPROVAL,
  APPROVE_TASK,
  REJECT_TASK,
  TASKS_BY_BENEFACTOR,
  ASSIGNED_TASKS,
  DELETE_TASK_ATTACHMENT,
  CHANGE_TASK_RESPONSIBLE,
  CHANGE_TASK_DEADLINE
} from '../actionTypes';
import generateAuthRequest from "../../helpers/generateAuthRequest";

export const searchTasks = (params) => ({
  type: SEARCH_TASKS,
  payload: generateAuthRequest('GET','api/Tasks', params)
});

export const addTask = (params) => ({
  type: ADD_TASK,
  payload: generateAuthRequest('POST','api/Tasks', params)
});

export const updateTask = (params) => ({
  type: UPDATE_TASK,
  //payload: generateAuthRequest('PUT',`api/Tasks/${params.taskId}`, params)
  payload: generateAuthRequest('PUT',`api/Tasks/update/${params.taskId}`, params)
}); 

export const deleteTask = (params) => ({
  type: DELETE_TASK,
  //payload: generateAuthRequest('DELETE',`api/Tasks/${params.taskId}`, params)
  payload: generateAuthRequest('DELETE',`api/Tasks/delete/${params.taskId}`, params)
});

export const insertTaskFile = (params) => ({
  type: TASK_INSERT_FILE,
  payload: generateAuthRequest('PUT',`api/Tasks/insert-file/${params.taskId}`, params.formData, params.taskId)
});

export const deleteTaskFile = (params) => ({
  type: TASK_DELETE_FILE,
  payload: generateAuthRequest('DELETE',`api/Tasks/delete-file/${params.taskId}/${params.fileName}`, params)
});

export const getTaskById = (params) => ({
  type: TASK_BY_ID,
  payload: generateAuthRequest('GET',`api/Tasks/${params.id}`, params)
});

export const completeTask = (params) => ({
  type: TASK_DONE,
  payload: generateAuthRequest('PUT',`api/Tasks/submit-task/${params.taskid}/${params.processid}`, params)
});

export const insertTaskAttachment = (params) => ({
  type: INSERT_TASK_ATTACHMENT,
  payload: generateAuthRequest('PUT',`api/Tasks/insert-attachments/${params.TaskId}/${params.ProcessId}/${params.taskOwner}`, params.formData) 
});

export const getTasksWaitingForApproval = (params) => ({
  type: TASKS_WAITING_FOR_APPROVAL,
  payload: generateAuthRequest('GET',`api/Tasks/fetch-waiting-tasks/${params.userId}`, params)  
});

export const approveTask = (params) => ({
  type: APPROVE_TASK,
  payload: generateAuthRequest('PUT',`api/Tasks/approve-task/${params.taskId}/${params.processId}/${params.taskResId}`, params)
});

export const rejectTask = (params) => ({
  type: REJECT_TASK,
  payload: generateAuthRequest('PUT',`api/Tasks/reject-task/${params.taskId}/${params.processId}/${params.taskResId}`, params)
});

export const  getTasksByBenefactor = (params) => ({
  type: TASKS_BY_BENEFACTOR,
  payload: generateAuthRequest('GET',`api/Tasks/fetch-benefactor-tasks/${params.userId}`, params) 
});

export const  getAssignedTasks = (params) => ({
  type: ASSIGNED_TASKS,
  payload: generateAuthRequest('GET',`api/Tasks/fetch-assigned-tasks/${params.userId}`, params) 
});

export const deleteTaskAttachment = (params) => ({
  type: DELETE_TASK_ATTACHMENT,
  payload: generateAuthRequest('DELETE',`api/Tasks/delete-file/${params.TaskId}/${params.ProcessId}/${params.taskOwner}/${params.fileName}`, params.formData) 
});

export const changeTaskResponsible = (params) => ({
  type: CHANGE_TASK_RESPONSIBLE,
  payload: generateAuthRequest('PUT',`api/Tasks/change-responsible-task/${params.taskId}/${params.processId}/${params.prevRes}`, params)
});

export const changeTaskDeadline = (params) => ({
  type: CHANGE_TASK_DEADLINE,
  payload: generateAuthRequest('PUT',`api/Tasks/change-deadline-task/${params.taskId}/${params.processId}/${params.responsible}`, params)
});