import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import "./mediaCard.scss";
import CardImage from "../../assets/images/layout/facecard.svg";
import TimeIco from "../../assets/images/layout/time.svg";
import config from "../../config/api.config.js";
import { logError } from "../../helpers/helpers";
import { getUserById } from "../../redux/users/action";
import defaultApprovalTaskImg from "../../assets/images/approvalTask.png";
import defaultArticleImg from "../../assets/images/article.png";
import defaultBenefitTaskImg from "../../assets/images/benefitTask.png";
import defaultTaskImg from "../../assets/images/task.png";
import defaultProcessImg from "../../assets/images/process.png";

const MediaCard = (props) => {
  const {
    cardTitle,
    cardText,
    onClick,
    cardImage,
    active,
    hasMarkup = false,
    type = null,
    authorId = null,
    cardContentClass = "",
    cardText2= "",
    role= ""
  } = props;
  const [authorImg, setAuthorImg] = useState("");
  const [readingTime, setReadingTime] = useState("");
  const [coverImage, setCoverImage] = useState(CardImage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authorId) {
      getAuthor(authorId);
    }
    updateReadingTime(cardText);
    if (cardImage) {
      setCoverImage(`${config.apiURL}/Resources/${cardImage}`);
    }else{
      if(role === 'Article'){
        setCoverImage(defaultArticleImg)
      }else if(role === 'Assigned Task'){
        setCoverImage(defaultTaskImg)
      }else if(role === 'Process'){
        setCoverImage(defaultProcessImg)
      }else if(role === 'Approval Task'){
        setCoverImage(defaultApprovalTaskImg)
      }else if(role === 'Benefactor Task'){
        setCoverImage(defaultBenefitTaskImg)
      }
      
    }
  }, []);

  const getAuthor = async (userId) => {  
    let image = "https://i.etsystatic.com/21073028/r/il/1b1907/2662292100/il_340x270.2662292100_t37c.jpg";     
    if(props.members && props.members[userId]?.image){    
      image = `${config.resourcesUrl+props.members[userId].image.name}`;        
    }else{
      await dispatch(getUserById({ id: userId }))
      .then((res) => {
        if (res.value?.data) {
          let user = res.value.data;
          if(user.image?.name){
            image = `${config.resourcesUrl+user.image.name}`; 
          }
        }  
      })
      .catch((err) => {
        logError(err);
      });
    }    
    setAuthorImg(image);
  };

  const updateReadingTime = (data) => {
    let strippedText = removeMarkup(data);
    let wordsCount = strippedText
      .trim()
      .split(" ")
      .filter(function (n) {
        return n !== "";
      }).length;
    setReadingTime(Math.round(wordsCount / 60));
  };

  const removeMarkup = (data) => {
    if (hasMarkup) {
      return data.replace(/<\/?[^>]+(>|$)/g, "");
    } else {
      return data;
    }
  };

  return (
    <Card
      className={active}
      onClick={onClick}
      cardtitle={cardTitle}
      cardtext={cardText}
      style={{
        width: "278px",
        margin: "8px",
        borderRadius: "25px",
        maxHeight: "330px",
        height: "330px",
        filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.043))",
        boxShadow:"none"
      }}
    >
      <CardMedia
        component="img"
        height="100"
        image={coverImage}
        alt="new card"
        style={{
          height: "133px",
          // width: "fit-content",
          margin: "0 auto",
        }}
      />
      <div style={{ paddingLeft: "15px", marginTop: "-25px" }}>
        <div>
          <img
            src={authorImg ? authorImg : "/profile.jpg"}
            className={"userphoto"}
            style={{ margin: "0 auto" }}
            alt="media file"
          />
        </div>
        <div
          style={{
            marginTop: "-25px",
            display: "flex",
            justifyContent: "right",
          }}
        >
          {type && <div className="first">{type}</div>}
        </div>
      </div>

      <CardContent className="media-card-head">
        <Typography gutterBottom variant="h6" component="div">
          {cardTitle.length > 37 ? cardTitle.substring(0, 37) + "..." : cardTitle}
        </Typography>
        <p className={`${cardContentClass}`}>
          {removeMarkup(cardText).substring(0, 99)}
          {cardText2 !== "" && <>
            <br />
            <span style={{lineHeight:1}}>{cardText2}</span>
          </>}
        </p>       
      </CardContent>
      <CardActions className="media-card-actions">
        <div style={{ display: "flex", width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              flexBasis: "50%",
              paddingLeft: "5px",
            }}
          >
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              flexBasis: "50%",
            }}
          >
            {type === 'Article' && <div className={"second"}>
              <img src={TimeIco} alt="img" />
              &nbsp;{readingTime}m
            </div>}
          </div>
        </div>
      </CardActions>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  members: state.user.byId,
});

export default connect(mapStateToProps, null)(MediaCard);
